/* You can add global styles to this file, and also import other style files */
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import '@angular/material/prebuilt-themes/indigo-pink.css';

html,
body {
    height: 100%;
}

/* body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
} */

.mat-body,
.mat-body-1,
.mat-typography .mat-body,
.mat-typography .mat-body-1,
.mat-typography {
    font: 400 14px / 8px Roboto, "Helvetica Neue", sans-serif !important;
}

/*rojo viejo #C90E2B rojo nuevo ff0000*  #e5e7e9 #4169E1*/
:root {
    --backgroundColor: #10598c;
    --textColor: #FFFFFF;
    --mcRed: #C90E2B;
    --mcBlueSure: #e5e7e9;
    --mcVerde: #198754;
   /* --mcBlueSure: #82CAFA; */

    /* --backgroundColor: #00FFFF;
    --textColor: #4169E1;
    --mcRed: #C90E2B;
    --mcBlueSure: #82CAFA; */
}



/*PARA LOS MODALES DE FORMA GENERAL*/

.modal {
  z-index: 999999 !important;
}


.modal-backdrop {
    opacity: 0.9 !important;
}

.modal-header {
    height: 50px;
}

.modal-header h2 {
    margin-top: 1rem;
}

/* .form-content-main{
    background-color: rgba(201, 14, 43, 0.3) !important;
} */

.form-box-style {
    width: 100%;
}

.form-box-style div .mat-form-field {
    width: 100%;
    padding-left: 20px;
    padding-bottom: 5px;
}

.form-box-style div .mat-radio-group {
    width: 100%;
    padding-left: 20px;
    padding-bottom: 5px;
}

.form-box-style div .mat-checkbox {
    width: 100%;
    padding-left: 20px;
    padding-bottom: 5px;
}

.fieldOld {
    width: 100%;
    padding-left: 20px;
    padding-bottom: 5px;
    border-radius: 4px;
}

.box-form {
    padding-top: 25px;
    min-width: 250px;
    max-width: 600px;
    background-color: #FFFFFF;
}


.box-form-title {
    background-color: var(--backgroundColor) !important;
    color: var(--textColor) !important;
    font-size: 20px !important;
    font-weight: 500;
    text-transform: capitalize !important;
    line-height: normal !important;
}

.box-form-title-tap {
    text-transform: uppercase !important;
    background-color: var(--mcBlueSure) !important;
    color: #000000 !important;
    font-size: 16px !important;
    font-weight: 400;
    line-height: normal !important;
}

.box-form-footer {
    padding: 15px;
}

.box-form-footer-old{
    padding: 15px;
}

.box-form-footer div {
    width: 100%;
}

.box-form-footer div button {
    width: 100%;
}

.box-form-footer .separater {
    width: 15px;
    max-width: 15px;
    min-width: 15px;
}

.cincopx {
    padding-right: 0px;
}

.mat-raised-button-add {
    min-width: 20px !important;
    line-height: 30px !important;
    padding: 0 20px !important;
    font-size: 20px !important;
    margin-top: 13px !important;
}

.sin {
    padding: 0px !important;
    margin: 0px !important;
}

.extra {
    padding-right: 0px !important;
}

.form-content-main-img {
  padding-top: 13%;
}

@media only screen and (min-width: 501px) and (max-width: 575px) {
    .extra {
        padding-right: 20px !important;
    }

}

.especial {
    margin-left: -10px !important;
}

@media only screen and (max-width: 500px) {

    .form-content-main-img {
        padding-top: 40%;
    }

    .mat-raised-button-add {
        padding: 0 10px !important;
    }

    .cincopx {
        padding-right: 5px;
    }

    .fieldOld {
        padding-left: 5px;
        padding-right: 5px;
    }

    .box-form-title {
        font-size: 16px !important;
    }

    .box-form-title-tap {
        font-size: 12px !important;
    }

    .row {
        --bs-gutter-x: 0 rem;
    }

    .box-form-footer {
        padding: 8px;
    }

    .box-form-footer .separater {
        width: 5px;
        max-width: 5px;
        min-width: 5px;
    }

    .form-box-style div .mat-form-field {
        padding-left: 5px;
        padding-right: 5px;
    }

    .especial{
        margin-left: 0px !important;
    }

    .form-box-style div .mat-radio-group {
        padding-left: 5px;
        padding-right: 5px;
    }

    .form-box-style div .mat-checkbox {
        padding-left: 5px;
        padding-right: 5px;
    }
}

@media only screen and (max-width: 350px) {
    .form-content-main-img {
        padding-top: 50%;
    }
}

.form-box-style div .mat-radio-group .mat-radio-button {
    color: var(--backgroundColor) !important;
    padding-bottom: 10px;
}

.navbar {
    z-index: 10006 !important;
}

.white-icon {
    color: white;
}

/* Note: If you're using an SVG icon, you should make the class target the `<svg>` element */
.white-icon svg {
    fill: white;
}


/*MIGAS DE PAN*/
.flexbox .padre {
    display: flex;
    align-items: center;
}

/*quitar*/
/* Structure */
table {
    width: 100%;
}

.mat-form-field {
    font-size: 14px;
    width: 100%;
}

.breadcrumb-item {
    padding-top: 10px;
    line-height: normal !important;
    font-weight: 500;
    font-size: 14px;
}

.breadcrumb-item span {
    color: var(--mcRed);
    font-weight: 400;
}

.btn-outline-dark:hover {
    color: var(--textColor) !important;
    background-color: var(--backgroundColor) !important;
    border-color: var(--backgroundColor) !important;
}

fieldset.scheduler-border {
    border: 1px groove var(--backgroundColor) !important;
    padding: 0px !important;
    margin: 0 0 0 0 !important;
    -webkit-box-shadow: 0px 0px 0px 0px #000;
    box-shadow: 0px 0px 0px 0px #000;
}

legend.scheduler-border-dos {
    font-size: 16px !important;
    font-weight: 500 !important;
    text-align: left !important;
    border: none;
    width: 190px !important;
    background-color: #FFFFFF !important;
    color: var(--backgroundColor) !important;
    padding: 5px;
    margin-top: -10px !important;
    margin-left: 10px;
}

legend.scheduler-border {
    font-size: 16px !important;
    font-weight: 500 !important;
    text-align: left !important;
    border: none;
    width: 210px;
    background-color: #FFFFFF !important;
    color: var(--backgroundColor) !important;
    padding: 5px;
    margin-top: -10px;
    margin-left: 10px;
}

legend {
  padding-bottom: 0px !important;
}

.icon-boostrap-tap {
    font-size: 20px !important;
}

.imagenClass {
    min-height: 200px;
    min-width: 200px;
    max-height: 200px;
    max-width: 200px;
}

.snackbarInformacion {
    background-color: rgba(114, 194, 121, 1) !important;
    color: #000000 !important;
    white-space: pre-wrap !important;
}

.snackbarError {
    background-color: rgba(220, 20, 60, 1) !important;
    color: #FFFFFF !important;
    white-space: pre-wrap !important;
}

.snackbarAdvertencia {
    background-color: rgba(255, 153, 0, 1) !important;
    color: #000000 !important;
    white-space: pre-wrap !important;
}

.snackbarInformacion .mat-button-wrapper {
    color: #000000 !important;
}

.snackbarError .mat-button-wrapper {
    color: #FFFFFF !important;
}

.snackbarAdvertencia .mat-button-wrapper {
    color: #000000 !important;
}

.spa {
    font-weight: 500 !important;
    padding-left: 10px;
}

.spafancy {
  padding-left: 10px;
}


.dialog-bg-trans {
    background-color: var(--mcRed) !important;
    opacity: 0.1 !important;
}


.custom-dialog-container .mat-dialog-container {
    background-color: #FFFFFF;
}



.btn-close:focus {
    outline: 0;
    box-shadow: 0 0 0 0rem !important;
    opacity: 1;
}


/**Tabla Teacher**/
table {
    width: 100%;
}

tr.example-detail-row {
    height: 0 !important;
}

tr.example-element-row:not(.example-expanded-row):hover {
    background: whitesmoke;
}

tr.example-element-row:not(.example-expanded-row):active {
    background: #efefef;
}

.example-element-row td {
    border-bottom-width: 0 !important;
}

.example-element-detail {
    overflow: hidden !important;
    display: flex !important;
}

.example-element-diagram {
    min-width: 80px !important;
    border: 2px solid black !important;
    padding: 8px !important;
    font-weight: lighter !important;
    margin: 8px 0 !important;
    height: 104px !important;
}

.example-element-symbol {
    font-weight: bold !important;
    font-size: 40px !important;
    line-height: normal !important;
}

.example-element-description {
    padding: 16px !important;
}

.example-element-description-attribution {
    opacity: 0.5 !important;
}

.col-mat-list {
    padding-top: 3px !important;
}

.btn:focus {
    outline: none;
    box-shadow: none;
}

.btn:focus-visible {
    outline: none;
    box-shadow: none;
}

.btn-check:active .btn-outline-dark:focus, .btn-check:checked .btn-outline-dark:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus, .btn-outline-dark:active:focus {
    box-shadow: none !important;
}

.up-div{
    z-index: 10002 !important;
    padding-right: 0px !important;
}

.mobileClassTool {
	margin-top: 9.1rem ;
}

 @media(max-width:991px) {
	 .mobileClassTool {
		margin-top:8.5rem;
	}
 }

 .btn-outline-dark {
    border-color: var(--backgroundColor) !important;
    color: var(--backgroundColor) !important;
 }

 .borderExpandDetail{
    border: 1px solid var(--backgroundColor);
    border-radius: 5px;
 }

 .modal-body div div {
    min-width: 250px;
    max-width: 500px;
 }

 .modal-header h2 {
    text-transform: capitalize;
 }

 .mainDivContent {
    margin-top: 10px;
    min-height: 15px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
 }

 .mainFilterStyle {
    margin: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-top: 15px;
 }

 .btnDarkCollapse{
    padding: 5px;
    margin-right: 30px;
    min-width: 100px;
 }

 .before-table {
    margin-top: 220;
    margin-left: 10px;
    margin-top: 20px;
    margin-right: 10px;
 }

 .before-table-div {
    min-width: 250px !important;
    max-width: 100% !important;
    margin-bottom: 20px;
 }

 .aling-radio-button {
    text-align: left !important;
    padding-bottom: 16px !important;
 }

 .btnFilter{
    margin-left: 10px !important;
    background-color: var(--mcBlueSure);
 }

 .select-customer-type {
    width: 100%;
    padding-top: 50px !important;
    padding-bottom: 30px !important;
 }

 .btn-natural {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-right: 3px;
 }

 .btn-institucion {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 3px;
 }

 .back-to-topw {
	position: fixed;
	display: inline;
	left: 15px;
	bottom: 15px;
	z-index: 99999;
}

/* .mat-tab-body-wrapper{
  min-height: 150px;
} */


.mainFilterStyled {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 15px;
}

.fade {
  z-index: 99991 !important;
}

.modal{
  z-index: 99999 !important;
}

.disabled-class {
  color: rgba(0,0,0,.38) !important;
}

fieldset.scheduler-border:disabled{
  border: 1px groove rgba(0,0,0,.38) !important;
}

legend.scheduler-border-dos-disabled {
  font-size: 16px !important;
  font-weight: 500 !important;
  text-align: left !important;
  border: none;
  width: 190px !important;
  background-color: #FFFFFF !important;
  color: rgba(0,0,0,.38) !important;
  padding: 5px;
  margin-top: -10px !important;
  margin-left: 10px;
}

legend.scheduler-border-disabled  {
  font-size: 16px !important;
  font-weight: 500 !important;
  text-align: left !important;
  border: none;
  width: 210px;
  background-color: #FFFFFF !important;
  color: rgba(0,0,0,.38) !important;
  padding: 5px;
  margin-top: -10px;
  margin-left: 10px;
}
