
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$mahuida_courses-primary: mat.define-palette(mat.$indigo-palette);
$mahuida_courses-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$mahuida_courses-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$mahuida_courses-theme: mat.define-light-theme((
  color: (
    primary: $mahuida_courses-primary,
    accent: $mahuida_courses-accent,
    warn: $mahuida_courses-warn,
  )
));

.mat-form-field ,.mat-radio-label-content {
  font-size: 16px !important;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: var(--backgroundColor) !important;
  font-weight: 400 !important;
  font-size: 16px !important;
}

.mat-form-field-appearance-legacy .mat-form-field-underline{
  background-color: var(--backgroundColor) !important;
}

.mat-form-field-appearance-legacy .mat-form-field-prefix .mat-datepicker-toggle-default-icon, .mat-form-field-appearance-legacy .mat-form-field-suffix .mat-datepicker-toggle-default-icon {
  width: 1.2em !important;
  color: var(--backgroundColor) !important;
}

.mat-form-field-appearance-legacy.mat-form-field-appearance-legacy-disabled  .mat-form-field-prefix .mat-datepicker-toggle-default-icon ,
.mat-form-field-appearance-legacy.mat-form-field-appearance-legacy-disabled  .mat-form-field-suffix .mat-datepicker-toggle-default-icon  {
  color: red !important;
}


.mat-form-field-ripple {
  background-color: var(--backgroundColor) !important;
}

.mat-calendar-body-selected{
  background-color: var(--backgroundColor) !important;
}

input[type="time"]::-webkit-calendar-picker-indicator{
  font-size: 1rem;
  filter: opacity(1) drop-shadow(0 0 0 var(--backgroundColor)) !important;
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: var(--backgroundColor) !important;
}

.mat-button.mat-primary,
.mat-stroked-button.mat-primary {
    color:  var(--backgroundColor) !important;
}
.mat-button.mat-primary:hover,
.mat-stroked-button.mat-primary:hover {
  background-color: var(--textColor) !important;
}

.mat-raised-button.mat-primary,
.mat-flat-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
  color: var(--textColor) !important;
  background-color: var(--backgroundColor) !important;
}

.mat-icon-button.mat-primary {
  color: var(--backgroundColor) !important;
}

tr.mat-row, tr.mat-footer-row {
  max-height: 35px !important;
  line-height: normal !important;
}

tr.mat-header-row {
  height: 45px !important;
}

.mat-header-cell {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: var(--textColor) !important;
  background-color: var(--backgroundColor) !important;
}

 /*material expande */
 .mat-expansion-panel-header-title, .mat-expansion-panel-header-description {
  min-height: 14px !important;
  line-height: normal !important;
}

.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: var(--backgroundColor) !important;
  }

  .mat-checkbox:not(.mat-checkbox-disabled) .mat-checkbox-ripple .mat-ripple-element {
  background-color: var(--backgroundColor) !important;
  }

  .mat-checkbox-background {
    border: 2px solid var(--backgroundColor) !important;
    background-color: transparent !important;
  }

  .mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
    color: var(--textColor) !important;
    border-color: var(--backgroundColor) !important;
    background-color: var(--backgroundColor) !important;
  }

  /*PARA LOS ZINDEX SE VEA EN MODAL BOOSTRAP*/
  // .cdk-global-overlay-wrapper, .cdk-overlay-container { z-index: 9999!important; }

  // .cdk-global-overlay-wrapper, .cdk-overlay-container {   z-index: 1055 !important;  }

  .modal{
    z-index: 1051 !important;
  }

 .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
    background-color:  rgb(16, 89, 140) !important;
    z-index: 9999 !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color:  rgb(16, 89, 140) !important;
    z-index: 9999 !important;
}

.mat-radio-button .mat-radio-outer-circle {
    z-index: 9999 !important;
}

.mat-radio-label-content{
  padding-left: 2px !important;
  padding-right: 10px !important;
}

//PARA DAR EL TAMAÑO DEL ICONO LUPA
.mat-form-field-appearance-legacy .mat-form-field-prefix .mat-icon-button .mat-icon, .mat-form-field-appearance-legacy .mat-form-field-suffix .mat-icon-button .mat-icon {
  font-size: 23px !important;
}

//list
.mat-list-base .mat-list-item.mat-list-item-with-avatar, .mat-list-base .mat-list-option.mat-list-item-with-avatar {
  height: 38px !important;
}

.mat-list-base .mat-list-item, .mat-list-base .mat-list-option {
  height: 38px !important;
}

.mat-list-base .mat-list-item .mat-list-item-content-reverse, .mat-list-base .mat-list-option .mat-list-item-content-reverse {
  display: flex;
  align-items: center;
  padding: 0px !important;
  flex-direction: row !important;
  justify-content: space-between !important;
}

.mat-list-base .mat-list-item.mat-list-item-with-avatar.mat-list-option .mat-list-item-content-reverse .mat-list-text, .mat-list-base .mat-list-item.mat-list-item-with-avatar.mat-list-option .mat-list-item-content .mat-list-text, .mat-list-base .mat-list-option.mat-list-item-with-avatar.mat-list-option .mat-list-item-content-reverse .mat-list-text, .mat-list-base .mat-list-option.mat-list-item-with-avatar.mat-list-option .mat-list-item-content .mat-list-text {
  padding-right: 0px !important;
  padding-left: 5px !important;
}

.mat-list-base .mat-list-item.mat-list-item-with-avatar .mat-list-item-content-reverse .mat-list-text, .mat-list-base .mat-list-item.mat-list-option .mat-list-item-content-reverse .mat-list-text, .mat-list-base .mat-list-option.mat-list-item-with-avatar .mat-list-item-content-reverse .mat-list-text, .mat-list-base .mat-list-option.mat-list-option .mat-list-item-content-reverse .mat-list-text {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.mat-list-base .mat-list-item .mat-list-text, .mat-list-base .mat-list-option .mat-list-text {
  flex: auto !important;
}

mat-list-item, mat-list-option {
  text-align: left !important;
}

// align specific child element
mat-list-option span {
  text-align: left !important;
}

.mat-step-text-label{
  line-height: normal !important;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header, .mat-vertical-stepper-header {
  padding: 10px 24px !important;
}

.mat-vertical-stepper-header , .mat-horizontal-stepper-header{
  pointer-events: none !important;
}

.cdk-overlay-container {
  z-index: 9999 !important
}

.mat-tab-body-wrapper {
  line-height: normal;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: normal !important;
  font-size: 16px !important;
  color:  var(--backgroundColor) !important;
}

.mat-radio-outer-circle {
  border-color: var(--backgroundColor) !important;
}

.mat-select-arrow {
  color: var(--backgroundColor) !important;
}

.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0,0,0,.38) !important;
}

.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle, .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0,0,0,.38) !important;
}

.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element, .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color:  rgba(0,0,0,.38) !important;
}

.mat-raised-button.mat-primary:disabled, .mat-flat-button.mat-primary:disabled, .mat-fab.mat-primary:disabled, .mat-mini-fab.mat-primary:disabled {
  color: var(--textColor) !important;
  background-color:  rgba(0,0,0,.38) !important;
}



.mat-form-field-appearance-legacy .mat-hint {
  color: var(--mcRed) !important;
}

.mat-form-field-prefix, .mat-form-field-suffix {
  color: var(--backgroundColor) !important;
}

th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type {
  padding-left: 7px !important;
}

//  th.mat-header-cell:last-of-type, td.mat-cell:last-of-type, td.mat-footer-cell:last-of-type {
//   width: 33px !important;
//   padding-right: 5px !important;
//   //line-height: normal;
//  }

 th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  padding-right:7px !important;
  line-height: normal;
}

.mat-paginator, .mat-paginator-page-size .mat-select-trigger {
  color: var(--backgroundColor) !important;
  font: 400 14px Roboto, "Helvetica Neue", sans-serif !important;
}

.mat-raised-button {
  min-width: 56px !important;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  z-index: 11000 !important;
}

.mat-tab-label{
  opacity: 0.8 !important;
  color: var(--backgroundColor) !important;
}

.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: var(--mcRed) !important;
}


.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: var(--backgroundColor) !important;
  border: 1px solid var(--backgroundColor);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar{
  background-color: var(--backgroundColor);
}

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color:  var(--mcRed) !important;
}

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.panelClass {
  white-space: pre-wrap !important;
}

.mat-tooltip-panel {
  line-height: normal !important;
 }

 .mat-tooltip {
  margin: 5px !important;
  color: white !important;
  background-color: var(--backgroundColor) !important;
  line-height: normal !important;
    font: 400 14px Roboto, "Helvetica Neue", sans-serif !important;
  }

  .mat-form-field-disabled .mat-form-field-underline {
    background-image: linear-gradient(to right,rgba(255,255,255,1) 0,rgba(0,0,0,.38) 33%,transparent 0) !important;
    background-size: 4px 100% !important;
    background-repeat: repeat-x !important;
    background-color: rgba(0,0,0,.38) !important;
  }

  .mat-form-field-disabled .mat-form-field-label {
    color: rgba(0,0,0,.38) !important;
  }

  .invisible-tabs {
    > .mat-tab-header {
      display: none;
    }
  }


//   .mat-raised-button.mat-primary.mat-button-disabled,
// .mat-flat-button.mat-primary.mat-button-disabled,
// .mat-fab.mat-primary.mat-button-disabled,
// .mat-mini-fab.mat-primary.mat-button-disabled {
//    background-color: rgba(0,0,0,.38) !important;
// }

// .mat-form-field-prefix, .mat-form-field-suffix {
//   margin-top: auto !important;
// }

// .mat-form-field-suffix {
//   padding-bottom: 3px
// }

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($mahuida_courses-theme);

